<template>
    <el-row>
        <el-col :span="24">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="年级">
                    <el-input v-model="grade"></el-input>
                </el-form-item>
                <el-form-item label="唯一号">
                    <el-input v-model="code"></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="name"></el-input>
                </el-form-item>
                <el-button @click="getData" type="primary">查询</el-button>
                <el-button @click="preImport" type="primary">导入</el-button>
                <el-button @click="danger" type="warning">结算</el-button>
                <el-button @click="clear" type="danger">清空</el-button>
            </el-form>
        </el-col>
        <el-col :span="24">
            <el-table :data="tableData" border style="width: 100%" size="small">
                <el-table-column fixed prop="grade" label="年级" width="150"></el-table-column>
                <el-table-column prop="class" label="班级" width="120"></el-table-column>
                <el-table-column prop="name" label="姓名" width="120">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>年级: {{ scope.row.grade }}</p>
                            <p>班级: {{ scope.row.class }}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">{{ scope.row.name }}</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="平台号" width="170"></el-table-column>
                <el-table-column prop="code" label="唯一号" width="170"></el-table-column>
                <el-table-column prop="mingxi" label="明细" width="300"></el-table-column>
                <el-table-column prop="amount" label="金额" width="100"></el-table-column>
                <el-table-column prop="tradeState" label="支付状态" width="100"></el-table-column>
                <el-table-column prop="orderDateTime" label="交易时间" width="300" :formatter="dateFormat"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="edit(scope.row)" size="small">
                            编辑
                        </el-button>
                        <el-button type="text" @click="del(scope.row)" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentIndex" :page-sizes="[10, 50, 100]" :page-size="currentSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </el-col>

        <el-dialog title="学生信息" :visible.sync="editing" v-if="currentStu">
            <el-form :model="currentStu" label-width="100px">
                <el-form-item label="年级">
                    <el-input v-model="currentStu.grade" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="班级">
                    <el-input v-model="currentStu.class" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="currentStu.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="唯一号">
                    <el-input v-model="currentStu.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="金额">
                    <el-input v-model="currentStu.amount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="明细">
                    <el-input v-model="currentStu.mingxi" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editing = false">取 消</el-button>
                <el-button type="primary" @click="saveStu">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="信息导入" :visible.sync="uploading">
            <el-upload class="upload-demo" :action="uploadUrl()" accept=".xls" :auto-upload="false" ref="upload" :on-success="uploadSuccess">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">只能上传Excel文件</div>
            </el-upload>

            <el-row>
                <el-form label-width="100px" v-if="cols_Grade">
                    <el-form-item label="年级列选择">
                        <el-radio-group v-model="col_Grade">
                            <el-radio v-for="i in cols_Grade" :label="i" v-bind:key="i">{{ i }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="班级列选择">
                        <el-radio-group v-model="col_Class">
                            <el-radio v-for="i in cols_Class" :label="i" v-bind:key="i">{{ i }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="姓名列选择">
                        <el-radio-group v-model="col_Name">
                            <el-radio v-for="i in cols_Name" :label="i" v-bind:key="i">{{ i }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="唯一号列选择">
                        <el-radio-group v-model="col_Code">
                            <el-radio v-for="i in cols_Code" :label="i" v-bind:key="i">{{ i }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="金额列选择">
                        <el-radio-group v-model="col_Amount">
                            <el-radio v-for="i in cols_Amount" :label="i" v-bind:key="i">{{ i }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="明细列选择">
                        <el-checkbox-group v-model="col_Mingxi">
                            <el-checkbox v-for="i in cols_Mingxi" :label="i" v-bind:key="i"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button @click="uploading = false">取 消</el-button>
                <el-button type="primary" @click="importExcel">确 定</el-button>
                <el-button type="success" @click="SaveSetting">保存设置</el-button>
            </div>
        </el-dialog>
    </el-row>
</template>
<script>
    import moment from 'moment'

    export default {
        created() {
            this.getData()
        },
        data() {
            return {
                tableData: null,
                grade: '',
                name: '',
                code: '',
                currentIndex: 1,
                currentSize: 10,
                total: 0,
                currentStu: null,
                editing: false,
                uploading: false,

                cols_Grade: null,
                cols_Class: null,
                cols_Name: null,
                cols_Code: null,
                cols_Amount: null,
                cols_Mingxi: null,

                col_Grade: '',
                col_Class: '',
                col_Name: '',
                col_Code: '',
                col_Amount: '',
                col_Mingxi: [],

                fileName: '',
            }
        },
        methods: {
            preImport() {
                this.uploading = true
            },
            importExcel() {
                this.$refs.upload.submit()
            },
            uploadUrl: function() {
                return process.env.VUE_APP_API_ROOT + 'StuList'
            },
            uploadSuccess(res) {
                //debugger;
                this.cols_Grade = res.cols
                this.cols_Class = res.cols
                this.cols_Code = res.cols
                this.cols_Name = res.cols
                this.cols_Amount = res.cols
                this.cols_Mingxi = res.cols
                this.fileName = res.fileName
            },
            SaveSetting() {
                let self = this

                if (!self.fileName || !self.col_Grade || !self.col_Class || !self.col_Name || !self.col_Code || !self.col_Amount || !self.col_Mingxi) {
                    this.$message.error('请先导入数据再保存设置')
                    return
                }
                self.rq({
                    url: 'SaveSetting',
                    params: {
                        fileName: self.fileName,
                        col_Grade: self.col_Grade,
                        col_Class: self.col_Class,
                        col_Name: self.col_Name,
                        col_Code: self.col_Code,
                        col_Amount: self.col_Amount,
                        col_Mingxi: self.col_Mingxi.join(','),
                    },
                })
                    .then(res => {
                        debugger
                        if (res) {
                            if (res.data.flag == 1) {
                                this.$message.info(res.data.msg)
                                self.getData()
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        }
                    })
                    .catch(err => {})
            },
            danger() {
                let self = this

                this.$confirm('此操作将结算此次交费并备份, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        self.rq({ url: 'settle' })
                            .then(res => {
                                debugger
                                if (res.data >= 0) {
                                    this.getData()
                                    this.$message({
                                        type: 'success',
                                        message: '结算完成!',
                                    })
                                }
                            })
                            .catch(err => {})
                    })
                    .catch(() => {})
            },
            clear() {
                let self = this
                this.$confirm('此操作清空学生表, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        self.rq({ url: 'student', method: 'delete' })
                            .then(res => {
                                debugger
                                if (res.data > 0) {
                                    this.getData()
                                    this.$message({
                                        type: 'success',
                                        message: '删除成功!',
                                    })
                                }
                            })
                            .catch(err => {})
                    })
                    .catch(() => {})
            },
            edit(row) {
                this.currentStu = row
                this.editing = true
            },
            del(row) {
                let self = this
                this.$confirm('删除该学生, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        debugger
                        self.rq({ url: 'student', method: 'delete', params: { id: row.id } })
                            .then(res => {
                                debugger
                                if (res.data > 0) {
                                    this.getData()
                                    this.$message({
                                        type: 'success',
                                        message: '删除成功!',
                                    })

                                    this.getData()
                                }
                            })
                            .catch(err => {})
                    })
                    .catch(() => {})
            },
            handleSizeChange(val) {
                this.currentSize = val
                this.getData()
            },

            handleCurrentChange(val) {
                this.currentIndex = val
                this.getData()
            },
            getData() {
                console.log(this.uploadUrl)
                let self = this
                self.rq({
                    url: 'stuList',
                    params: {
                        pageIndex: self.currentIndex,
                        pageSize: self.currentSize,
                        grade: self.grade,
                        name: self.name,
                        code: self.code,
                    },
                })
                    .then(res => {
                        self.tableData = res.data.data
                        self.total = res.data.total
                        console.log(res.data)
                        //debugger;
                    })
                    .catch(err => {
                        //debugger;
                    })
            },
            saveStu() {
                let self = this
                self.rq({
                    url: 'student',
                    method: 'post',
                    params: {
                        id: self.currentStu.id,
                        grade: self.currentStu.grade,
                        cla: self.currentStu.class,
                        name: self.currentStu.name,
                        code: self.currentStu.code,
                        tradeState: self.currentStu.tradeState,
                        amount: self.currentStu.amount,
                        mingxi: self.currentStu.mingxi,
                    },
                })
                    .then(res => {
                        debugger
                        if (res.data > 0) {
                            this.getData()
                            this.$message({
                                type: 'success',
                                message: '保存成功!',
                            })

                            this.getData()
                        }
                    })
                    .catch(err => {})
            },
            dateFormat: function(row, column) {
                var date = row[column.property]
                if (date === undefined) {
                    return ''
                }
                return moment(date).format('YYYY-MM-DD HH:mm:ss')
            },
        },
    }
</script>
